import React, {useState, useEffect} from "react";
import {motion} from "framer-motion";
import style from "./style.module.scss";
import axios from "axios";
import {useTranslation} from "react-i18next";
import PositiveIcon from "./../../assets/PositiveResponseIcon.svg";
import ErrorIcon from "./../../assets/ErrorResponseIcon.svg";

const textAnimation = {
    hidden: {
        y: -100,
        opacity: 0,
    },
    visible: (custom) => ({
        y: 0,
        opacity: 1,
        transition: {delay: custom * 0.3},
    }),
};
const useValidation = (value, validations) => {
    const [isEmpty, setEmpty] = useState(true);
    const [minLengthError, setMinLengthError] = useState(false);
    const [maxLengthError, setMaxLengthError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [innError, setInnError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [inputValid, setInputValid] = useState(false);

    useEffect(() => {
        for (const validation in validations) {
            switch (validation) {
                case "minLength":
                    value.length < validations[validation]
                        ? setMinLengthError(true)
                        : setMinLengthError(false);
                    break;
                case "isEmpty":
                    value ? setEmpty(false) : setEmpty(true);
                    break;
                case "maxLength":
                    value.length > validations[validation]
                        ? setMaxLengthError(true)
                        : setMaxLengthError(false);
                    break;
                case "isEmail":
                    const re =
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                    re.test(String(value).toLowerCase())
                        ? setEmailError(false)
                        : setEmailError(true);
                    break;
                case "isInn":
                    const reInn = /^(\d{10}|\d{12})$/;
                    reInn.test(String(value).toLowerCase())
                        ? setInnError(false)
                        : setInnError(true);
                    break;
                case "isName":
                    const reName = /^[А-ЯЁа-яёA-Za-z]+$/;
                    reName.test(String(value).toLowerCase())
                        ? setNameError(false)
                        : setNameError(true);
                    break;
                case "isPhone":
                    const rePhone = /^\+\d{1} \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
                    rePhone.test(String(value).toLowerCase())
                        ? setPhoneError(false)
                        : setPhoneError(true);

                    break;
            }
        }
    }, [value]);

    useEffect(() => {
        if (
            phoneError ||
            isEmpty ||
            maxLengthError ||
            minLengthError ||
            emailError ||
            innError ||
            nameError
        ) {
            setInputValid(false);
        } else {
            setInputValid(true);
        }
    }, [
        phoneError,
        isEmpty,
        maxLengthError,
        minLengthError,
        emailError,
        innError,
        nameError,
    ]);

    return {
        phoneError,
        nameError,
        innError,
        isEmpty,
        minLengthError,
        emailError,
        maxLengthError,
        inputValid,
    };
};
const useInput = (initialValue, validations) => {
    const [value, setValue] = useState(initialValue);
    const [isDirty, setDirty] = useState(false);
    const valid = useValidation(value, validations);

    const onChange = (e) => {
        setDirty(true);
        setValue(e.target.value);
    };

    return {
        value,
        setValue,
        onChange,
        isDirty,
        ...valid,
    };
};
export default function LearnMore({calculatorResult, refForm}) {
    const {t} = useTranslation();

    const inn = useInput("", {
        isEmpty: true,
        minLength: 10,
        maxLength: 12,
        isInn: true,
    });
    const [companyName, setCompanyName] = useState("");
    const firstName = useInput("", {isEmpty: true, minLength: 2, isName: true});
    const email = useInput("", {isEmpty: true, minLength: 2, isEmail: true});
    const [positiveResponse, setPositiveResponse] = useState(false);
    const [errorResponse, setErrorResponse] = useState(false);

    const [comment, setComment] = useState("");
    const phoneNumber = useInput("", {
        isEmpty: true,
        minLength: 2,
        isPhone: true,
    });
    const [dataProcessingConsent, setDataProcessingConsent] = useState(false);
    const [informationConsent, setInformationConsent] = useState(false);

    const fetchCompanyName = (e) => {
        const url =
            "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";
        const token = "beb168dcb3f3bd4e1bed3d1b8a381b8522483657";

        const options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Token " + token,
            },
            body: JSON.stringify({query: e.target.value}),
        };

        fetch(url, options)
            .then((response) => response.json())
            .then((result) => {
                if (result.suggestions && result.suggestions.length > 0) {
                    setCompanyName(result.suggestions[0].value);
                } else {
                    setCompanyName("");
                }
            })
            .catch((error) => console.log("Error", error));
    };

    const handleInputChange = (event) => {
        const formattedValue = formatPhoneNumber(event.target.value);
        phoneNumber.onChange({
            target: {
                value: formattedValue,
            },
        });
    };

    const formatPhoneNumber = (value) => {
        const cleanedValue = value.replace(/\D/g, "");

        const matches = cleanedValue.match(
            /^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})$/
        );

        if (matches) {
            const formattedValue = `+${matches[1]} (${matches[2]}) ${matches[3]}-${matches[4]}-${matches[5]}`;
            return formattedValue.replace(/[\s()-]+$/, "");
        }

        return value;
    };
    const [source, setSource] = useState("");

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const source = urlParams.get("source");
        setSource(source || "Карбон Копи");
    }, []);

    const firstEmail = "info@carboncopy.ru";
    const secondEmail = "d.zelenov@carboncopy.ru";
    const thirdEmail = "etitova@carboncopy.ru";
    const sixthEmail = "v.biryukov@uk-klassiki.ru";
    const fourthEmail = "a.zhukov@gilk.ru";
    const subjectText = `Новый лид! ${companyName}`;
    const messageText = `Вам поступила новая заявка!\n\nИсточник: ${source}\n\nИНН: ${inn.value}\nНазвание компании: ${companyName}\nКонтактное лицо: ${firstName.value}\nEmail: ${email.value}\nТелефон: ${phoneNumber.value}\n\nКомментарий к заявке: ${comment}\n\nПредварительный расчет: ${calculatorResult}`;
    const resetForm = () => {
        inn.setValue("");
        setCompanyName("");
        firstName.setValue("");
        email.setValue("");
        setComment("");
        phoneNumber.setValue("");
        setDataProcessingConsent(false);
        setInformationConsent(false);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        axios
            .post("https://proxy.ctrl.lc:3001/api/v1/email/", {
                email: firstEmail,
                subject: subjectText,
                message: messageText,
            })
            .catch((error) => {
                console.error("Error:", error);
            });
        axios
            .post("https://proxy.ctrl.lc:3001/api/v1/email/", {
                email: secondEmail,
                subject: subjectText,
                message: messageText,
            })
            .catch((error) => {
                console.error("Error:", error);
            });
        axios
            .post("https://proxy.ctrl.lc:3001/api/v1/email/", {
                email: thirdEmail,
                subject: subjectText,
                message: messageText,
            })
            .catch((error) => {
                console.error("Error:", error);
            });

        axios
            .post("https://proxy.ctrl.lc:3001/api/v1/email/", {
                email: fourthEmail,
                subject: subjectText,
                message: messageText,
            })
            .catch((error) => {
                console.error("Error:", error);
            });
        axios
            .post("https://proxy.ctrl.lc:3001/api/v1/email/", {
                email: sixthEmail,
                subject: subjectText,
                message: messageText,
            })
            .then((response) => {
                resetForm();
                setPositiveResponse(true);

                setTimeout(() => {
                    setPositiveResponse(false);
                }, 4000);
            })
            .catch((error) => {
                setErrorResponse(true);
                setTimeout(() => {
                    setErrorResponse(false);
                }, 4000);
                resetForm();
            });
        resetForm();
    };

    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);

    const openModal1 = () => {
        setIsOpen1(true);
    };

    const closeModal1 = () => {
        setIsOpen1(false);
    };
    const openModal2 = () => {
        setIsOpen2(true);
    };

    const closeModal2 = () => {
        setIsOpen2(false);
    };

    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const closeConfirmationModal = () => {
        setIsConfirmationModalOpen(false);
    };
    const closeErrorModal = () => {
        setIsErrorModalOpen(false);
    };
    return (
        <>
            <motion.div
                ref={refForm}
                initial="hidden"
                whileInView="visible"
                viewport={{amount: 0.2, once: true}}
                className={style.learnMore}
            >
                <div className="container">
                    <div className={style.learnMore__container}>
                        <motion.div
                            custom={1}
                            variants={textAnimation}
                            className={style.inform}
                        >
                            <h4 className={style.inform__title}>Узнать больше!</h4>
                            <div className={style.inform__quation}>
                                <h6>1. Как стать партнером Carbon Copy?</h6>
                                <p>Как стать партнером Carbon Copy и быстро запустить новый канал дистрибуции
                                    ИТ-оборудования за счет предоставления клиентам сервиса адаптивной подписки?</p>
                            </div>
                            <div className={style.inform__quation}>
                                <h6>2. Как перейти на аренду ИТ-оборудования?</h6>
                                <p>Как ускорить Time-to-market проектов в компании, переведя закупку ИТ-оборудования на
                                    подписную систему с фиксированным ежемесячным платежом?</p>
                            </div>
                            <p className={style.inform__more}>
                                Узнайте подробнее о возможностях работы с нашей компанией, оставив контактные данные в
                                форме обратной связи.
                            </p>
                        </motion.div>
                        <motion.div
                            custom={2}
                            variants={textAnimation}
                            className={style.form}
                        >
                            <form onSubmit={handleSubmit}>
                                <div className={style.inputContainer}>
                                    <input
                                        className={style.input}
                                        onBlur={fetchCompanyName}
                                        type="text"
                                        placeholder="ИНН:"
                                        name="inn"
                                        value={inn.value}
                                        onChange={(e) => inn.onChange(e)}
                                    />
                                    {inn.minLengthError && inn.isEmpty && inn.innError && (
                                        <div className={style.inputIncorrect}>
                                            Это поле обязательно для заполнения*
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <input
                                        className={style.input}
                                        type="text"
                                        name="companyName"
                                        placeholder="Название компании:"
                                        value={companyName}
                                        readOnly
                                    />
                                </div>
                                <div className={style.inputContainer}>
                                    <input
                                        className={style.input}
                                        type="text"
                                        name="firstName"
                                        placeholder="Ваше имя"
                                        onChange={(e) => firstName.onChange(e)}
                                        value={firstName.value}
                                    />
                                    {firstName.minLengthError &&
                                        firstName.isEmpty &&
                                        firstName.nameError && (
                                            <div className={style.inputIncorrect}>
                                                Это поле обязательно для заполнения*
                                            </div>
                                        )}
                                </div>

                                <div className={style.inputContainer}>
                                    <input
                                        className={style.input}
                                        type="email"
                                        name="email"
                                        placeholder={t("email")}
                                        value={email.value}
                                        onChange={(e) => email.onChange(e)}
                                    />
                                    {email.minLengthError &&
                                        email.isEmpty &&
                                        email.emailError && (
                                            <div className={style.inputIncorrect}>
                                                Это поле обязательно для заполнения*
                                            </div>
                                        )}
                                </div>
                                <div className={style.inputContainer}>
                                    <input
                                        className={style.input}
                                        type="tel"
                                        placeholder="+7 (___) ___-__-__"
                                        name="phone"
                                        value={phoneNumber.value}
                                        onChange={(e) => {
                                            phoneNumber.onChange(e);
                                            const cleanedValue = e.target.value.replace(/\D/g, "");

                                            handleInputChange({target: {value: cleanedValue}});
                                        }}
                                    />
                                    {phoneNumber.minLengthError &&
                                        phoneNumber.isEmpty &&
                                        phoneNumber.phoneError && (
                                            <div className={style.inputIncorrect}>
                                                Это поле обязательно для заполнения*
                                            </div>
                                        )}
                                </div>
                                <div>
                                    <input
                                        className={style.textarea}
                                        placeholder="Комментарий:"
                                        as="textarea"
                                        name="comment"
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                    />
                                </div>
                                <div className={style.checkbox}>
                                    <input
                                        type="checkbox"
                                        checked={dataProcessingConsent}
                                        onChange={(e) => setDataProcessingConsent(e.target.checked)}
                                        name="dataProcessingConsent"
                                    />
                                    <div className={style.textContainer}>
                                        Я ознакомился с <a href="/terms-and-privacy">Пользовательским соглашением и
                                        Обработкой перональных данных</a> и принимаю их
                                    </div>
                                </div>
                                <div className={style.checkbox}>
                                    <input
                                        type="checkbox"
                                        checked={informationConsent}
                                        onChange={(e) => setInformationConsent(e.target.checked)}
                                        name="informationConsent"
                                    />
                                    <div className={style.textContainer}>
                                        Я являюсь представителем ЮЛ
                                    </div>
                                </div>
                                <div className={style.button}>
                                    <button
                                        disabled={
                                            !email.inputValid ||
                                            !phoneNumber.inputValid ||
                                            !firstName.inputValid ||
                                            !inn.inputValid ||
                                            !dataProcessingConsent ||
                                            !informationConsent
                                        }
                                        type="submit"
                                    >
                                        Отправить
                                    </button>
                                </div>
                                {positiveResponse && (
                                    <div className={style.positiveResponseContainer}>
                                        <div className={style.positiveResponse}>
                                            <img src={PositiveIcon} alt=""/>
                                            <h3 className={style.positiveResponse}>Заявка принята</h3>
                                            <p className={style.positiveResponse}>
                                                Мы скоро Вам перезвоним
                                            </p>
                                        </div>
                                    </div>
                                )}
                                {errorResponse && (
                                    <div className={style.positiveResponseContainer}>
                                        <div className={style.positiveResponse}>
                                            <img src={ErrorIcon} alt=""/>
                                            <h3 className={style.positiveResponse}>
                                                Что-то пошло не так
                                            </h3>
                                            <p className={style.positiveResponse}>
                                                Попробуйте еще раз позже
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </form>
                        </motion.div>
                    </div>
                    {isOpen1 && (
                        <div className={style.modal}>
                            <div className={style.modal__content}>
                                <h2>Согласие на обработку персональных данных</h2>
                                <p>Настоящим свободно, своей волей и в своем интересе, в соответствии с п. 4 ст. 9
                                    Федерального закона от 27 июля 2006 года № 152-ФЗ «О персональных данных»,
                                    пользователь предоставляет свое безусловное согласие на автоматизируемую и
                                    неавтоматизированную обработку любых его персональных данных, предоставленных им
                                    лично, в том числе продолжая использование сайта https://carboncopy.ru (далее –
                                    сайт), с целью получения информации, услуг или заключения договоров с ООО «Карбон
                                    Копи» (ОГРН 1197746440847, ИНН 7727423442), зарегистрированным в соответствии с
                                    законодательством РФ по адресу: 117638, Россия, г. Москва, ул. Одесская, д.2, офис
                                    39/5/WP (далее по тексту — Оператор).</p>
                                <p>Персональные данные – это любая информация, относящаяся к определенному или
                                    определяемому на основании такой информации физическому лицу.</p>
                                <p>Настоящее Согласие выдано пользователем на обработку следующих персональных данных: —
                                    Полное имя; — Пол; — Дата рождения; — Гражданство; — Страховой номер индивидуального
                                    лицевого счета (СНИЛС); — Индивидуальный номер налогоплательщика (ИНН); — Номер
                                    мобильного телефона; — Номер домашнего телефона; — Адрес электронной почты; — Адрес
                                    места проживания; — Адрес регистрации; — Идентификационные данные документа,
                                    удостоверяющего личность; — Файлы cookie, сведения о действиях, которые совершаются
                                    пользователем, сведения об используемых для этого устройствах, дата и время сессии,
                                    с использованием метрических программ в автоматическом режиме; — [_____________]
                                    (иной объект персональных данных, заполняется при необходимости).</p>
                                <p>Согласие дано Оператору для совершения следующих действий с персональными данными
                                    пользователя с использованием средств автоматизации и/или без использования таких
                                    средств: сбор, запись, систематизация, накопление, хранение, уточнение (обновление,
                                    изменение), извлечение, использование, блокирование, удаление, уничтожение,
                                    обезличивание, передача (предоставление доступа) третьим лицам (в том числе
                                    аффилированным лицам и партнёрам Оператора), в том числе трансграничная передача, а
                                    также осуществление любых иных действий, предусмотренных действующим
                                    законодательством РФ как неавтоматизированными, так и автоматизированными
                                    способами.:</p>
                                <p>Данное согласие дается Оператору для обработки персональных данных пользователя в
                                    следующих целях: — предоставление пользователю услуг/работ; — направление в адрес
                                    пользователя уведомлений, касающихся предоставляемых услуг/работ; — подготовка и
                                    направление ответов на запросы пользователя; — направление в адрес пользователя
                                    информации, в том числе рекламной, о мероприятиях/товарах/услугах/работах Оператора.
                                    — улучшения работы сайта и сервисов Оператора, совершенствования продуктов и услуг
                                    Оператора и его аффилированных лиц, обеспечения безопасности использование продуктов
                                    и сервисов Оператора, определения предпочтений пользователя, предоставления целевой
                                    информации по продуктам и услугам Оператора и партнеров Оператора.</p>
                                <p>Настоящее согласие действует со дня его предоставления до момента его отзыва путем
                                    направления соответствующего уведомления на электронный адрес
                                    personal_data@carboncopy.ru.</p>
                                <p>В случае отзыва пользователем согласия на обработку персональных данных Оператор
                                    вправе продолжить обработку персональных данных без согласия пользователя при
                                    наличии оснований, указанных в пунктах2 – 11 части 1 статьи 6, части 2 статьи 10 и
                                    части 2 статьи 11 Федерального закона от 27 июля 2006 года № 152-ФЗ «О персональных
                                    данных». В случае отзыва пользователем согласия на обработку персональных данных
                                    пользователь считается проинформированным о необходимости прекратить использование
                                    сайта.</p>
                                <button onClick={closeModal1}>
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.63586 5.63599L18.3638 18.3639"
                                            stroke="#fff"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M5.63586 18.364L18.3638 5.63609"
                                            stroke="#fff"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    )}
                    {isOpen2 && (
                        <div className={style.modal}>
                            <div className={style.modal__content}>
                                <h2>Согласие на направление информационных материалов</h2>
                                <p>Настоящим даю согласие на получение информационных рассылок и рекламных сообщений об
                                    услугах и/или предложениях ООО «Карбон Копи» (ОГРН 1197746440847, ИНН 7727423442),
                                    зарегистрированным в соответствии с законодательством РФ по адресу: 117638, Россия,
                                    г. Москва, ул. Одесская, , д.2, офис 39/5/WP, посредством направления на указанные
                                    мною номер телефона и адрес электронной почты, в том числе передачу третьим лицам: —
                                    sms-сообщений; — электронных писем; — сообщений в мессенджерах; — push-уведомлений;
                                    — телефонных звонков. Данное согласие действует бессрочно, если иной срок не будет
                                    установлен законодательством Российской Федерации. Я проинформирован о возможности в
                                    любой момент отозвать своё согласие посредством направления ООО «Карбон Копи»
                                    соответствующего уведомления об этом не менее чем за один месяц до даты фактического
                                    прекращения обработки моих персональных данных на электронный адрес
                                    info@carboncopy.ru.:</p>
                                <button onClick={closeModal2}>
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.63586 5.63599L18.3638 18.3639"
                                            stroke="#fff"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M5.63586 18.364L18.3638 5.63609"
                                            stroke="#fff"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    )}
                    {isConfirmationModalOpen && (
                        <div className={style.modalOk}>
                            <div className={style.modalOk__content}>
                                <h2>
                                    Заявка отправлена. Мы с Вами свяжемся в течение 2-х рабочих
                                    дней.
                                </h2>
                                <button onClick={closeConfirmationModal}>
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.63586 5.63599L18.3638 18.3639"
                                            stroke="#fff"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M5.63586 18.364L18.3638 5.63609"
                                            stroke="#fff"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    )}
                    {isErrorModalOpen && (
                        <div className={style.modalOk}>
                            <div className={style.modalOk__content}>
                                <h2>
                                    Ошибка!! Не удалось отправить анкету. Попробуйте позже или
                                    напишите нам на почту info@carboncopy.ru
                                </h2>
                                <button onClick={closeErrorModal}>
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.63586 5.63599L18.3638 18.3639"
                                            stroke="#fff"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M5.63586 18.364L18.3638 5.63609"
                                            stroke="#fff"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </motion.div>
        </>
    );
}
