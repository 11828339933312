import React from "react";
import style from "./style.module.scss";
import Logo from "./../../assets/Logo.png";
import { useTranslation } from "react-i18next";

export default function Rules() {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={style.infoHeader}>
        <img src={Logo} alt="" />
        <div className={style.infoText}>
          <div>
            <p>Carbon Copy</p>
            <p>OOO «КАРБОН КОПИ»</p>
          </div>
          <div className={style.infoLink}>
            <a className={style.link} href="tel:+78002452323">
              8 800 245 23 23
            </a>
            <a className={style.link} href="mailto:info@carboncopy.ru">
              info@carboncopy.ru
            </a>
            <a className={style.link} href="https://carboncopy.ru">
              carboncopy.ru
            </a>
          </div>
        </div>
      </div>
      <div className={style.infoBottom}>
        <h2>Правила пользования продуктом «Аренда с выкупом» при продаже юридическим лицам</h2>
        <h3>1. Общая информация</h3>
        <p>1.1. Период действия продукта: с 00:00 01.02.2024 г. по 23:59 01.02.2025 г. (время московское).</p>
        <p>1.2. Акция начинается в 10:00 01.02.2024 г в розничных магазинах, в круглосуточных — в 00:00 01.02.2024 г.</p>
        <p>1.3. Акция может быть завершена досрочно.</p>
        <h3>2. Механика акции</h3>
        <p>2.1. Перечень товаров, возможных для аренды, с указанием модели является единым для всех городов-участников.</p>
        <p>2.2. Поставщик продукта вправе вводить и выводить из списка новые товары без дополнительного уведомления.</p>
        <p>2.3. Продукт позволяет юридическим лицам приобретать товары в аренду на срок от 1 года до 3 лет с последующим выкупом товаров по цене 1000 рублей.</p>
        <p>2.4. Покупатель определяет необходимые ему товары и направляет запрос в ООО «Карбон Копи» о проведении анализа возможности передать данные товары в аренду. Запрос направляется либо ответственным менеджером продавца, либо покупателем с сайта ООО «Карбон Копи», расположенного по адресу www.carboncopy.ru</p>
        <p>2.5 При принятии ООО «Карбон Копи» положительного решения о передаче товара в аренду и согласования размера арендных платежей, покупатель заключает с ООО «Карбон Копи» договор аренды, установленного образца.</p>
        <p>2.6. После подписания договора аренды с ООО «Карбон Копи» покупатель забирает товар у продавца или оформляет доставку.</p>
        <p>2.6 По окончании срока аренды, при условии оплаты в адрес ООО «Карбон Копи» всех арендных платежей, ООО «Карбон Копи» передает товар в собственность покупателю.</p>
        <h3>3. Арендодатель</h3>
        <p>3.1 Арендодателем товаров является ООО «Карбон Копи.</p>
        <h3>4. Аудитория акции</h3>
        <p>В акции принимают участие только юридические лица.</p>
        <h3>5. Правила обмена и возврата товаров</h3>
        <p>5.1. Гарантийное обслуживание, обмен и возврат некачественного товара, приобретенного в период действия продукта, осуществляются продавцом в общем порядке, предусмотренном действующим законодательством.</p>
        <h3>6. Прочие условия</h3>
        <p>6.1. Товары, участвующие в акции, продаются только юридическим лицам в период действия продукта на общую сумму не менее 1 000 000 (одного миллиона) рублей.</p>
      </div>
    </div>
  );
}
