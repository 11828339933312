import React from 'react'
import { motion } from 'framer-motion'
import style from './style.module.scss'
import Logo from './../../assets/Logo2.png'
import { useTranslation } from "react-i18next"

const textAnimation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: custom => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },

  }),
}

export default function Advantages() {
  const { t } = useTranslation();
  return (
    <>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.2, once: true }}
        className={style.advantages}>
        <div className="container">
          <div className={style.containerTop}>
            <motion.div custom={1} variants={textAnimation} className={style.title}>
              <h4>Преимущества использования</h4>
              <p>HaaS <span>/</span> DaaS</p>
            </motion.div>
            <div className={style.logo}>
              <img src={Logo} alt="Logo" />
            </div>
          </div>
          <div className={style.containerDown}>
            <motion.div custom={2} variants={textAnimation} className={style.example}>
              <div className={style.example__number}>
                <p>1.</p>
              </div>
              <p className={style.example__title}>Снижение единовременных расходов</p>
              <p className={style.example__subtitle}>Подписка HaaS / DaaS позволяет быстрее реализовывать проекты при переходе от капитального к операционному бюджету. Решение Carbon Copy не предполагает наличия первоначального взноса и кредитной нагрузки на клиента</p>
            </motion.div>
            <motion.div custom={3} variants={textAnimation} className={style.example}>
              <div className={style.example__number}>
                <p>2.</p>
              </div>
              <p className={style.example__title}>Отсутствие валютных рисков</p>
              <p className={style.example__subtitle}>HaaS / DaaS от Carbon Copy – это фиксированная цена контрактов в рублях. Чтобы ни происходило с курсовыми колебаниями на мировых рынках, стоимость контракта на закупку оборудования останется неизменной.</p>
            </motion.div>
            <motion.div custom={4} variants={textAnimation} className={style.example}>
              <div className={style.example__number}>
                <p>3.</p>
              </div>
              <p className={style.example__title}>Мультивендорные поставки</p>
              <p className={style.example__subtitle}>Carbon Copy не ставит клиентам рамки по объему закупки оборудования конкретного вендора. В модели подписки доступны позиции из продуктовой матрицы Carbon Copy с возможностью их модификации.</p>
            </motion.div>
            <motion.div custom={5} variants={textAnimation} className={style.example}>
              <div className={style.example__number}>
                <p>4.</p>
              </div>
              <p className={style.example__title}>SLA в формате «одного окна»</p>
              <p className={style.example__subtitle}>Полный мультивендорный SLA в формате «одного окна» гарантирует доступность оборудования на уровне не ниже 95% и включает гарантийную поддержку производителей, страховое покрытие в случаях, не покрываемых гарантией производителей, подменный фонд и первую линию поддержки 24/7.</p>
            </motion.div>
            <motion.div custom={6} variants={textAnimation} className={style.example}>
              <div className={style.example__number}>
                <p>5.</p>
              </div>
              <p className={style.example__title}>Обновление и право выкупа</p>
              <p className={style.example__subtitle}>В рамках закупки оборудования по модели HaaS / DaaS клиенту доступно право регулярного обновления текущего оборудования на новое при пролонгации договора или право выкупа оборудования в любой период действия договора.</p>
            </motion.div>
            <motion.div custom={7} variants={textAnimation} className={style.example}>
              <div className={style.example__number}>
                <p>6.</p>
              </div>
              <p className={style.example__title}>Лояльность сотрудников</p>
              <p className={style.example__subtitle}>Carbon Copy предлагает компаниям возможность приобретать по подписке пользовательские устройства для личного использования сотрудниками. Решение позволит расширить или запустить востребованную программу лояльности, формируя привлекательный HR-бренд.</p>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </>
  )
}
