import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import style from './style.module.scss'
import { useTranslation } from "react-i18next"

const textAnimation = {
    hidden: {
        y: -100,
        opacity: 0,
    },
    visible: custom => ({
        y: 0,
        opacity: 1,
        transition: {delay: custom * 0.3},

    }),
}


export default function Calculator( {onSubmitForm} ) {
    const {t} = useTranslation();
    const [amount, setAmount] = useState('');
    const [term, setTerm] = useState('');
    const [downPayment, setDownPayment] = useState('');
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const [dailyPayment, setDailyPayment] = useState(0);

    useEffect(() => {
        let resultText = ``;
        if (monthlyPayment && dailyPayment) {
            resultText += `
                Сумма предмета лизинга (руб.): ${amount}
                Срок (годы): ${term} год
                Аванс (%): ${downPayment}%
                Месячный платеж: ${monthlyPayment.toFixed(2)} руб.
                Ежедневный платеж: ${dailyPayment.toFixed(2)} руб.
            `;
        } else {
            resultText += '“Отсутствует”';
        }
        onSubmitForm(resultText);
    }, [monthlyPayment, dailyPayment]);

    const calculatePayments = () => {
        const matrix = [
            [],
            [
                {
                    0: 18.5,
                    10: 16.7,
                    20: 14.8,
                    30: 13,
                    40: 11.1,
                    49: 9.4,
                }
            ],
            [
                {
                    0: 38,
                    10: 34.2,
                    20: 30.4,
                    30: 26.6,
                    40: 22.8,
                    49: 19.4,
                }
            ],
            [
                {
                    0: 55.5,
                    10: 54.6,
                    20: 48.3,
                    30: 42.3,
                    40: 36.3,
                    49: 30.9,
                }
            ],
        ];

        function getEnhancedRate( term ) {
            if (matrix[term] && matrix[term][0]) {
                const rate = matrix[term][0][downPayment];
                return rate;
            }
        }

        const appreciationMatrix = getEnhancedRate(term);

        const appreciationMatrixSum = appreciationMatrix / 100;
        const amountNumber = parseInt(amount, 10);
        const monthlyPayment = (amountNumber + (amountNumber * appreciationMatrixSum)) / (parseInt(term, 10) * 12);
        const dailyPayment = monthlyPayment / 30;


        setMonthlyPayment(monthlyPayment);
        setDailyPayment(dailyPayment);

    };

    const handleSubmit = ( e ) => {
        e.preventDefault();
        calculatePayments();
    };


    return (
        <>
            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{amount: 0.2, once: true}}
                className={style.calculator}>
                <div className="container">
                    <motion.h2 custom={1} variants={textAnimation}>Калькулятор услуг аренды с правом выкупа</motion.h2>
                    <motion.div custom={2} variants={textAnimation} className={style.calculator__container}>
                        <form onSubmit={handleSubmit}>
                            <div className={style.input}>
                                <label htmlFor="amount">Сумма предмета аренды (руб.):</label>
                                <input
                                    placeholder='от 1 000 000 р.'
                                    type="number"
                                    id="amount"
                                    value={amount}
                                    onChange={( e ) => setAmount(e.target.value)}
                                    required
                                    min="1000000"
                                />
                            </div>
                            <div className={style.input}>
                                <label htmlFor="term">Срок (годы):</label>
                                <select id="term" value={term} onChange={( e ) => setTerm(e.target.value)} required>
                                    <option value="">Выберите срок</option>
                                    <option value="1">1 год</option>
                                    <option value="2">2 год</option>
                                    <option value="3">3 год</option>
                                </select>
                            </div>
                            <div className={style.input}>
                                <label htmlFor="downPayment">Аванс (%):</label>
                                <select
                                    id="downPayment"
                                    value={downPayment}
                                    onChange={( e ) => setDownPayment(e.target.value)}
                                    required
                                >
                                    <option value="">Выберите аванс</option>
                                    <option value="0">0%</option>
                                    <option value="10">10%</option>
                                    <option value="20">20%</option>
                                    <option value="30">30%</option>
                                    <option value="40">40%</option>
                                    <option value="49">49%</option>
                                </select>
                            </div>
                            <div className={style.button}>
                                <button type="submit">Рассчитать</button>
                            </div>
                        </form>
                        {monthlyPayment > 0 && dailyPayment > 0 && (
                            <div className={style.result}>
                                <h3>Результаты:</h3>
                                <p>Ежемесячный платеж: {monthlyPayment.toFixed(2)} руб.</p>
                                <p>Ежедневный платеж: {dailyPayment.toFixed(2)} руб.</p>
                            </div>
                        )}
                    </motion.div>

                </div>
            </motion.div>
        </>
    )
}
