import React from 'react'
import { motion } from 'framer-motion'
import style from './style.module.scss'
import { useTranslation } from "react-i18next"


const textAnimation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: custom => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },

  }),
}
export default function AboutUs() {
  const { t } = useTranslation();

  return (
    <>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.2, once: true }}
        className={style.aboutUs}>
        <div className="container">
          <motion.h2 custom={1} variants={textAnimation} className={style.title}>CARBON COPY - первый Финтех-проект в России, позволяющий приобретать инфраструктурное и пользовательское ИТ-оборудование по подписке</motion.h2>
          <motion.h4 custom={2} variants={textAnimation} className={style.decisions__title}>Решения разработаны для:</motion.h4>
          <motion.div custom={3} variants={textAnimation}  className={style.decisions}>
            <div className={style.decisions__example}>
                <div>
                  <p>Юридических лиц</p>
                </div>
                <p className={style.decisions__example__text}><span>Carbon Copy</span> предлагает юридическим лицам пользовательское и инфраструктурное оборудование в удобном формате: по подписке или в лизинг.​</p>
              </div>
            <div className={style.decisions__example}>
              <div>
                <p>Вендоров</p>
              </div>
              <p className={style.decisions__example__text}><span>Carbon Copy</span> позволят вендорам расширять свои продуктовые предложения за счет адаптивного финансового сервиса.</p>
            </div>
            <div className={style.decisions__example}>
              <div>
                <p>Дистрибьюторов</p>
              </div>
              <p className={style.decisions__example__text}><span>Carbon Copy</span> позволят привлекать интеграторов за счет предоставления более гибких условий оплаты и исключения финансовой нагрузки.​</p>
            </div>
            <div className={style.decisions__example}>
              <div>
                <p>Интеграторов</p>
              </div>
              <p className={style.decisions__example__text}><span>Carbon Copy</span> снимает финансовую нагрузку и ускоряет выплаты Интегратору, стимулирует развитие партнерского канала.​​</p>
            </div>
            <div className={style.decisions__example}>
              <div>
                <p>DATA - центров</p>
              </div>
              <p className={style.decisions__example__text}><span>Carbon Copy</span> предлагает подписную систему с ежемесячным платежом на выгодных условиях, отсутствие первоначального взноса и высокий уровень SLA.​</p>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </>
  )
}
