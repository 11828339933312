import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import style from "./style.module.scss";
import { useTranslation } from "react-i18next";
import Logo from "./../../assets/Logo.png";
import Logo2 from "./../../assets/Logo2.png";
import { Link } from "react-router-dom";
import Logo3 from "./../../assets/LOGOMV.png";
import Arrow from "./../../assets/fi-rr-arrow-right.svg";
import Telegram from './../../assets/TelegramIcon.svg'


const textAnimation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

export default function HomeMvideo() {
  const { t } = useTranslation();
  return (
    <>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.2, once: true }}
        className={style.home}
      >
        <div className={style.container}>
          <div className={style.BackToHome}>
            <Link className={style.link} to="/">
              Carbon Copy
            </Link>
            <img src={Arrow} alt="" />
            <Link to="#">MVideo</Link>
          </div>
          <div className={style.logo}>
            <Link to="/">
              <img className={style.logo1} src={Logo} alt="Logo" />
            </Link>
            <Link to="https://www.mvideo.ru/promo/tekhnika-v-arendu-dlya-yuridicheskih-lic">
              <img className={style.logo3} src={Logo3} alt="Logo" />
            </Link>
            <div>
              <div className={style.connection}>
                <a href="tel:+74994605810">+7 (499) 460-58-10</a>
                <a href="https://t.me/carboncopy0?text=Здравствуйте! Меня интересует лизинг IT-оборудования">
                  <img src={Telegram} alt="Telegram"/>
                </a>
              </div>
              <img className={style.logo2} src={Logo2} alt="Logo"/>
            </div>
          </div>
          <motion.h1
              custom={1}
              variants={textAnimation}
            className={style.title1}
          >
            Партнерская программа с Мвидео
          </motion.h1>
          <motion.p
            custom={2}
            variants={textAnimation}
            className={style.subtitle}
          >
            Техника для бизнеса нужна здесь и сейчас? Возьми её в аренду. Компания Carbon Copy поможет с оформлением, а в конце аренды ты сможешь выкупить заказ за 1000 ₽. Зарабатывай с новой техникой уже сегодня!
          </motion.p>
        </div>
      </motion.div>
    </>
  );
}
