import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import style from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Logo from "./../../assets/Logo.png";
import Logo2 from "./../../assets/Logo2.png";
import Telegram from "./../../assets/TelegramIcon.svg";

const textAnimation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

export default function Home({buttonHandler, refForm}) {
  const { t } = useTranslation();
  const textList = [
    'Серверы',
    'Системы хранения данных',
    'Переключатели',
    'Маршрутизаторы',
    'Телекоммуникационное оборудование',
    'Ноутбуки',
    'Персональные компьютеры',
    'Тонкие клиенты',
    'Моноблоки',
    'Смартфоны',
    'Печатное оборудование',
  ];
  const delay = 4000; // Задержка между сменой строк (в миллисекундах)
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % textList.length);
    }, delay);

    return () => clearTimeout(timer);
  }, [currentIndex, textList.length, delay]);

  return (
    <>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.2, once: true }}
        className={style.home}
      >
        <div className="container">
          <div className={style.logo}>
            <Link to="/">
              <img className={style.logo1} src={Logo} alt="Logo"/>
            </Link>
            <div>
              <div className={style.connection}>
                <a href="tel:+74994605810">+7 (499) 460-58-10</a>
                <a href="https://t.me/carboncopy0?text=Здравствуйте! Меня интересует лизинг IT-оборудования">
                  <img src={Telegram} alt="Telegram"/>
                </a>
              </div>
              <img className={style.logo2} src={Logo2} alt="Logo"/>
            </div>
          </div>
          <motion.h1
              custom={1}
              variants={textAnimation}
              className={style.title1}
          >
            Инфраструктурное и пользовательское
          </motion.h1>
          <motion.h1
              custom={1}
              variants={textAnimation}
              className={style.title2}
          >
            ит-оборудование по подписке
          </motion.h1>
          <motion.p
              custom={2}
              variants={textAnimation}
              className={style.subtitle}
          >
            {textList[currentIndex]}
          </motion.p>
          <div className={style.button}>
            <button onClick={() => buttonHandler(refForm)}>Отправить заявку</button>
          </div>
        </div>
      </motion.div>
    </>
  );
}
