import React, {useRef, useState, useEffect, forwardRef} from "react";
import Home from "../../components/Home";
import AboutUs from "../../components/AboutUs";
import WhatIs from "../../components/WhatIs";
import Advantages from "../../components/Advantages";
import LearnMore from "../../components/LearnMore";
import Calculator from "../../components/Calculator";
import Footer from "../../components/Footer";
import Partners from "../../components/Partners";
import FeedbackWidget from "../../components/FeedbackWidget/FeedbackWidget";

const Main = () => {
    const [calculatorResult, setCalculatorResult] = useState("");
    const handleCalculatorSubmit = (resultText) => {
        setCalculatorResult(resultText);
    };
    const [isVisible, setIsVisible] = useState(true);
    const refForm = React.useRef(null);



    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const buttonHandler = (ref) => {
        ref.current.scrollIntoView({behavior: "smooth", inline: "center"});
    };

    return (
        <>
            <Home refForm={refForm} buttonHandler={buttonHandler}/>
            <AboutUs/>
            <WhatIs/>
            <Advantages/>
            <Partners/>
            <Calculator onSubmitForm={handleCalculatorSubmit}/>
            <LearnMore refForm={refForm} calculatorResult={calculatorResult}/>
            <Footer/>
            {isVisible && <FeedbackWidget calculatorResult={calculatorResult}/>}
        </>
    );
};

export default Main;
