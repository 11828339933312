import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import style from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LogoMVideo from "../../assets/LOGOMV.png";

const textAnimation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

export default function Partners() {
  const { t } = useTranslation();
  return (
    <>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.2, once: true }}
        className={style.partners}
      >
        <div className="container">
          <motion.h2
            custom={1}
            className={style.title}
            variants={textAnimation}
          >
            Партнерская программа с Мвидео
          </motion.h2>
          <div className={style.subtitleContainer}>
            <motion.p
              custom={2}
              variants={textAnimation}
              className={style.subtitle}
            >
              Техника для бизнеса нужна здесь и сейчас? Возьми её в аренду. Компания Carbon Copy поможет с оформлением, а в конце аренды ты сможешь выкупить заказ за 1000 ₽. Зарабатывай с новой техникой уже сегодня!
            </motion.p>
            <Link to="https://www.mvideo.ru/promo/tekhnika-v-arendu-dlya-yuridicheskih-lic">
              {" "}
              <img src={LogoMVideo} alt="" />
            </Link>
          </div>
          <motion.div
            variants={textAnimation}
            custom={2}
            className={style.button}
          >
            <Link to="/with-Mvideo">Узнать больше</Link>
          </motion.div>
        </div>
      </motion.div>
    </>
  );
}
