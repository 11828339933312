import React from 'react';
import { Routes, Route } from "react-router-dom";
import './scss/App.scss'
import Main from "./pages/Main/Main";
import MVideo from "./pages/Mvideo/MVideo";
import Rules from './pages/MVideoRules/Rules';
import TermsAndPrivacy from './pages/TermsAndPrivacy/TermsAndPrivacy';

function App() {
  return (
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/with-Mvideo" element={<MVideo />} />
        <Route path="/with-Mvideo/rules" element={<Rules />} />
        <Route path="/terms-and-privacy" element={<TermsAndPrivacy />} />
      </Routes>
  );
}

export default App;
