


// fallback style
const FallbackLoading = (
    <div>
    ......
    </div> 
  )

export default FallbackLoading