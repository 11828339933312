import React from "react";
import style from "./style.module.scss";

export default function TermsAndPrivacy() {
  return (
    <div className="container">
      <div className={style.infoBottom}>
        <h2>Пользовательское соглашение</h2>
        <p>г. Москва</p>
        <h3>1. Общие условия пользования Сайтом</h3>
        <p>
          1.1. Настоящее Соглашение о пользовании сайтом{" "}
          <a className={style.link} href="https://www.carboncopy.ru/">https://www.carboncopy.ru/</a>{" "}
          (далее по тексту – Соглашение), принадлежащего ООО «Карбон Копи» (ИНН
          7727423442, ОГРН 1197746440847) – далее Общество) регулирует права и
          обязанности сторон, возникающие при использовании сайта{" "}
          <a className={style.link}  href="https://www.carboncopy.ru/">https://www.carboncopy.ru/</a>,
          находящегося в сети Интернет.
        </p>
        <p>
          1.2. Пользователь сети Интернет принимает данное соглашение полностью
          без каких-либо изъятий и оговорок, в том числе путем совершения им
          конклюдентных действий по использованию сайта, включающих в себя поиск
          и просмотр информации, регистрацию на сайте, размещение объявлений на
          сайте, а также любые иные действия по использованию функционала сайта.
        </p>
        <p>
          1.3. Настоящее соглашение представляет собой публичною оферту в смысле
          ст. 437 Гражданского кодекса Российской Федерации. Пользователь,
          приступивший к использованию Сайта, считается подтвердившим свое
          согласие с условиями настоящего Соглашения в порядке, предусмотренном
          ч. 3 ст. 434 Гражданского кодекса Российской Федерации.
        </p>
        <p>
          1.4. Независимо от факта Регистрации или авторизации Пользователя на
          Сайте, использование Сайта, включая просмотр размещенной на Сайте
          информации, означает согласие Пользователя с настоящим
          Пользовательским соглашением и принятие на себя обязательств следовать
          инструкциям по использованию Сервисов, а также ответственности за
          действия, связанные с использованием Сайта.
        </p>
        <p>
          1.5. Осуществляя доступ к Сайту и заключая таким образом настоящее
          Соглашение, Пользователь гарантирует, что обладает всеми правами и
          полномочиями, необходимыми для заключения и исполнения Соглашения, в
          том числе является совершеннолетним и полностью дееспособным лицом,
          либо несовершеннолетним лицом, объявленным по решению уполномоченного
          органа полностью дееспособным (эмансипация) либо несовершеннолетним
          лицом, достигшим четырнадцати лет и получившим письменное разрешение в
          требуемой законом форме от своих родителей или иных законных
          представителей на заключение Пользовательского соглашения. Общество
          вправе в любое время потребовать от Пользователя предоставление
          информации и документов, подтверждающих права и полномочия, как
          указано выше.
        </p>
        <p>
          1.6. Пользовательское соглашение размещено и всегда доступно на сайте
          по адресу{" "}
          <a className={style.link}  href="https://www.carboncopy.ru/terms-and-privacy">
            https://www.carboncopy.ru/terms-and-privacy
          </a>
          . Соглашение может быть изменено Обществом в любое время без
          какого-либо специального уведомления об этом. Новая редакция
          Пользовательского соглашения вступает в силу с момента ее размещения
          на Сайте, если в ней прямо не указано иное. Регулярное ознакомление с
          действующей редакцией Пользовательского соглашения является
          обязанностью Пользователя.
        </p>
        <p>
          1.7. Дальнейшее использование Пользователем Сайта после внесения
          подобных изменений означает согласие Пользователя с соответствующими
          изменениями. В случае несогласия Пользователя с настоящим Соглашением
          или его обновлениями (изменениями), Пользователь обязан отказаться от
          использования Сайта.
        </p>
        <p>
          1.8. К Соглашению и отношениям между Пользователем и Обществом,
          возникающим в связи с использованием Сайта, применяются нормы
          действующего законодательства Российской Федерации. Все права на Сайт
          в целом, включая права использование сетевого адреса (доменного имени)
          принадлежат Обществу.
        </p>
        <p>
          1.9. Общество предоставляет доступ к Сайту всем заинтересованным лицам
          в соответствии с настоящим Соглашением и действующим законодательством
          Российской Федерации.
        </p>
        <p>
          1.10. Общество сохраняет за собой право в любое время изменять
          оформление Сайта, его содержание, изменять или дополнять используемые
          тексты, программное обеспечение и другие объекты, используемые или
          хранящиеся на Сайте, любые материалы в любое время с предварительным
          уведомлением или без такового.
        </p>
        <p>
          1.11. Пользователь понимает и соглашается с тем, что Общество может
          разместить на Сайте рекламу. Используя Сайт, Пользователь признает
          право Общества размещать рекламу без предварительного уведомления.
          Общество не несет ответственности за взаимоотношения пользователя и
          рекламодателей, разместивших рекламу на Сайте, в том числе, в
          отношении самой рекламы, а также товаров и услуг таких рекламодателей
          - третьих лиц. Пользователь понимает и соглашается, что Сайт не
          определяет содержание и не несет ответственности за такую информацию,
          включая сайты, ссылки на которые могут содержаться в соответствующих
          материалах.
        </p>
        <p>
          1.12. Сообщения Общества, предназначенные для Пользователей,
          публикуются для всеобщего доступа на Сайте и/или рассылаются
          индивидуально по электронным адресам, предоставленным Пользователями
          при Регистрации или на Сайте. При этом Пользователь понимает,
          принимает и соглашается, что рассылаемые сообщения и/или их отдельные
          части могут иметь рекламный характер, а также могут содержать
          рекламные, информационные и иные сообщения контрагентов Общества.
          Сообщения, опубликованные на Сайте, считаются доставленными
          Пользователю с момента их публикации.
        </p>
        <p>
          1.13. Сообщения Пользователей, предназначенные для Общества,
          пересылаются способами, предложенными на Сайте.
        </p>
        <p>
          1.14. Общество не оказывает консультаций по вопросам, не относящимся к
          Сайту, требующим профессиональной оценки и/или не входящих в ее
          компетенцию.
        </p>
        <p>
          1.15. Обращения Пользователя в Общество по вопросам, связанным с
          использованием Сайта, рассматриваются в порядке, предусмотренном на
          Сайте. Взаимодействие Общества с Пользователем в рамках рассмотрения
          обращения Пользователя осуществляется с использованием указанного
          Пользователем адреса электронной почты.
        </p>
        <p>
          1.16. Общество вправе не рассматривать обращения Пользователя: не
          содержащие информацию и документы, необходимые для рассмотрения
          обращения; содержащие ложную информацию и (или) документы, не
          обладающими признаками достоверности; в отношении вопросов, по которым
          ранее Обществом был направлен ответ Пользователю (повторные
          обращения); содержащие оскорбления, угрозы или обращения, изложенные в
          резкой негативной форме; направленные с нарушением иных условий и
          порядка рассмотрения обращений.
        </p>
        <h3>2. Термины и определения</h3>
        <p>
          В настоящем Пользовательском соглашении термины, указанные ниже, имеют
          следующее значение:
        </p>
        <p>
          2.1. Общество — ООО «Карбон Копи» (ИНН 7727423442, ОГРН
          1197746440847).
        </p>
        <p>
          2.2. Сайт — Интернет-ресурс, представляющий собой совокупность
          содержащихся в информационной системе информации и объектов
          интеллектуальной собственности (в том числе, программа для ЭВМ, база
          данных, графическое оформление интерфейса (дизайн) и др.), доступ к
          которому обеспечивается с различных пользовательских устройств,
          подключенных к сети Интернет, посредством специального программного
          обеспечения для просмотра веб-страниц (браузер) по адресам
          <a className={style.link}  href="https://www.carboncopy.ru/">
            https://www.carboncopy.ru/
          </a>{" "}
          (включая домены следующих уровней, относящихся к данным адресам) или
          Мобильных приложений.
        </p>
        <p>
          2.3. Пользователь — посетитель ресурсов сети Интернет, в том числе
          Сайта.
        </p>
        <p>
          2.4. Соглашение — настоящее соглашение, и иные правила и документы,
          регламентирующие работу Сайта или определяющие порядок использования
          Сервисов, опубликованных на Сайте.
        </p>
        <p>
          2.5. Сервисы — функциональные возможности, службы, услуги,
          инструменты, доступные для Пользователей на Сайте.
        </p>
        <p>
          2.6. Мобильное приложение — программное обеспечение для портативных
          (мобильных) устройств, установленное или загружаемое Пользователем на
          такие устройства с помощью различных программных платформ,
          предназначенное для доступа к Сайту.
        </p>
        <p>
          2.7. Объявление — информационное сообщение с предложением об
          оказываемых услугах и/или реализуемых товарах (включая контактную
          информацию, фотографии и любую сопутствующую информацию).
        </p>
        <p>
          2.8. Заявка — направление Пользователем в Общество через форму
          обратной связи сообщение или совершение звонка на телефон Общества,
          указанный на сайте с целю получения расчета / коммерческого
          предложения по товарам и услугам, реализуемым Обществом.
        </p>
        <p>
          2.9. Учетные данные — уникальный логин (адрес электронной почты) и
          пароль, создаваемые самостоятельно Пользователем в процессе
          Регистрации на Сайте, используемые для доступа к дополнительным
          сервисам.
        </p>
        <p>
          2.10. Личный кабинет — персональный раздел Пользователя на Сайте,
          связанный с учетной записью Пользователя на Сайте, в котором
          Пользователю доступно управление отдельными Сервисами Сайта, в том
          числе их заказ, подключение / отключение, на предложенных Сайтом
          условиях.
        </p>
        <p>
          2.11. Регистрация — совокупность действий Пользователя в соответствии
          с указанными на Сайте инструкциями, включая предоставление Учетных
          данных и иной информации, совершаемых Пользователем с использованием
          специальной формы пользовательского интерфейса Сайта в целях
          формирования Личного кабинета и получения доступа к отдельным Сервисам
          Сайта.
        </p>
        <p>
          2.12. Сведения — любые материалы и информация, предоставляемые
          Пользователем в связи с использованием Сайта, в том числе и без
          осуществления регистрации.
        </p>
        <h3>
          3. Права на результаты интеллектуальной деятельности, размещенные на
          Сайте
        </h3>
        <p>
          3.1. Все используемые и размещенные на Сайте результаты
          интеллектуальной деятельности, а также сам Сайт являются
          интеллектуальной собственностью Общества и охраняются
          законодательством об интеллектуальной собственности Российской
          Федерации, а также соответствующими международными правовыми
          конвенциями.
        </p>
        <p>
          3.2. Никакой контент Сайта не может быть скопирован (воспроизведен),
          переработан, распространен, опубликован, сохранен, передан, продан или
          иным способом использован целиком или по частям, без предварительного
          разрешения Общества, кроме случаев, когда Общества явным образом
          выразила свое согласие на свободное использование материала любым
          лицом, за исключением случаев, установленных настоящим Соглашением, а
          также действующим законодательством Российской Федерации.
        </p>
        <p>
          3.3. При размещении каких-либо материалов в разделы Сайта Пользователь
          тем самым автоматически предоставляет Обществу безвозмездное,
          постоянное, безотзывное, неисключительное право на использование,
          воспроизведение, изменение, редактирование, опубликование, перевод и
          распространение таких материалов (частично или полностью) на
          территории всех стран мира и/или включение таких материалов в другие
          произведения в любой форме посредством использования любых средств
          массовой информации или технологий, которые известны в настоящее время
          или могут быть изобретены в будущем, на весь срок охраны авторского
          права, предусмотренный законодательством РФ, в отношении таких
          материалов.
        </p>
        <h3>4. Доступ к сервисам Сайта</h3>
        <p>
          4.1. Пользователи на условиях настоящего Соглашения от своего имени в
          и своем интересе имеют возможность воспользоваться доступными на Сайте
          Сервисами, включая направление заявок, просмотра предложений товаров и
          услуг Общества и иных предлагаемых на Сайте Сервисов. В отношении
          использования отдельных Сервисов Сайта могут быть установлены
          дополнительные условия, правила и ограничения.
        </p>
        <p>
          4.2. Общество вправе в любое время пересматривать или изменять условия
          предоставления Сервисов, дополнять, изменять, ограничивать, расширять
          функциональные возможности Сайта и/или Сервисов, в том числе условия
          доступа Пользователя к Сервисам или отдельным функциональным
          возможностям Сайта.
        </p>
        <p>
          4.3. Сервисы предоставляются Пользователю бесплатно, если специально
          не указано иное.
        </p>
        <p>
          4.4. Предоставление отдельных Сервисов может регулироваться
          специальными правилами и/или соглашениями, являющимися неотъемлемой
          частью настоящего Соглашения.
        </p>
        <p>
          4.5. В случае противоречия или несоответствия между текстом настоящего
          Соглашения и специальными правилами и/или соглашениями применению
          подлежат последние.
        </p>
        <p>
          4.6. Предоставляемые на Сайте Сервисы в любой момент могут изменяться,
          дополняться, обновляться, менять форму и характер функциональных
          возможностей без предварительного уведомления Пользователя, в связи с
          чем их использование предлагается в режиме «как есть», т.е. том виде и
          объеме, в каком они предоставляются Обществом в момент обращения к
          Сервисам Пользователей. Общество вправе при необходимости по
          собственному усмотрению прекратить (временно или окончательно)
          предоставление Сервисов (или каких-либо отдельных функций в рамках
          Сервисов) всем Пользователям в целом или отдельному Пользователю, в
          частности, без предварительного уведомления.
        </p>
        <h3>5. Регистрация на Сайте</h3>
        <p>
          5.1. При Регистрации указываются учетные данные, которые Пользователь
          вносит самостоятельно. После внесения данных для регистрации
          Пользователь получает электронное письмо на адрес электронной почты,
          указанный при регистрации, содержащее активную гиперссылку, переход по
          которой необходим для подтверждения Регистрации на Сайте. Регистрация
          учетной записи осуществляется на один адрес электронной почты
          Пользователя однократно. Повторная Регистрация новой учетной записи на
          Сайте с использованием ранее указанного при Регистрации адреса
          электронной почты не допускается. Регистрация также может
          осуществляться путем направления СМС-кода на указанный при регистрации
          номер мобильного телефона Пользователя.
        </p>
        <p>
          5.2. Пользователь обязан следить за сохранностью своих Учетных данных
          и не раскрывать их третьим лицам. Пользователь не вправе передавать
          свои Учетные данные третьим лицам, а также прямо или косвенно
          разрешать третьим лицам использовать его Учетные данные для
          авторизации на Сайте, за исключением лиц, действующих от имени и в
          интересах Пользователя или получивших такие Учетные данные на
          основании соответствующих соглашений с Пользователем.
        </p>
        <p>
          5.3. Любое действие, совершенное Пользователем с использованием его
          Учетных данных, считается действием, совершенным самим Пользователем
          или уполномоченным им лицом, и устанавливает обязанности и
          ответственность для Пользователя в отношении таких действий, включая
          ответственность за нарушение настоящего Соглашения, требований
          законодательства в отношении Товара.
        </p>
        <p>
          5.4. Пользователь обязан немедленно изменить Учетные данные, если у
          него есть причины подозревать, что эти данные были раскрыты или могут
          быть использованы неуполномоченными им третьими лицами.
        </p>
        <p>
          5.5. Общество вправе использовать доступные технические решения для
          проверки правильности информации, предоставляемой Пользователем при
          использовании Сайта.
        </p>
        <p>
          5.6. Общество вправе заблокировать доступ Пользователя к Сайту.
          Общество вправе в любое время прекращать и/или ограничить доступ
          Пользователя к Сервисам, а также заблокировать или удалить учетную
          запись Пользователя на Сайте без возможности ее восстановления.
        </p>
        <p>
          5.7. Направление заявок и просмотр иной информации, размещенной на
          Сайте в открытом доступе, не требует регистрации и/или авторизации
          Пользователя, однако при совершении таких действий Пользователь в
          любом случае обязан соблюдать положения Пользовательского соглашения.
        </p>
        <p>
          5.8. Нахождение Пользователя на Сайте более 10 секунд означает
          присоединение Пользователя к условиям настоящего Пользовательского
          соглашения, а также его согласие на использование Обществом
          файлов-cookie в соответствии с Политикой ООО «Карбон Копи» в отношении
          обработки персональных данных.
        </p>
        <p>
          5.9. Отправка Заявки через форму обратной связи на сайте или
          совершение звонка на телефон Общества, указанный на сайте, означает
          согласие Пользователя на сбор, обработку и хранение персональных
          данных по форме, указанной в Приложении № 1 к настоящему Соглашению, а
          именно Пользователь дает свое согласие на обработку Обществом ООО
          «Карбон Копи» (адрес местонахождения: 125375, г. Москва, вн.тер.г.
          муниципальный округ Пресненский, Большой Гнездниковский пер., д. 1,
          стр. 2, ОГРН 1197746440847) и его аффилированными лицами, и/или
          третьими лицами, действующими по поручению Общества, персональных
          данных, предоставленных при направлении Заявки и/или совершении звонка
          в Общество (фамилия, имя, контактные данные – адреса электронной
          почты, а также иные данные, относящиеся к личности пользователя и
          ставшие доступными Обществу в связи с обращением Пользователя).
          Обработка персональных данных Пользователя осуществляется в
          соответствии с действующим законодательством Российской Федерации и в
          соответствии с Политикой ООО «Карбон Копи» в отношении обработки
          персональных данных путем смешанной обработки персональных данных
          пользователя (автоматизированным и неавтоматизированным способами), с
          передачей таких данных по внутренней сети Общества и сети Интернет.
          Обработка персональных данных Пользователя включает совершение любого
          действия (операции) или совокупности действий (операций) с
          использованием средств автоматизации или без использования таких
          средств с персональными данными, включая сбор, запись, систематизацию,
          накопление, хранение, уточнение (обновление, изменение), извлечение,
          использование, обезличивание, блокирование, удаление, уничтожение,
          передачу (распространение, предоставление, доступ) персональных
          данных, включая обработку вышеуказанными способами, в том числе для
          целей: предоставления доступа к Сайту/ к учетной записи на Сайте,
          предоставления консультационной поддержки в связи с использованием
          Сайта, направления расчетов и коммерческих предложений по реализации
          товаров и услуг Общества и/или партнеров Общества, получения
          статистических и аналитических данных для улучшения функционирования
          Сайта и/или Сервисов, расширения спектра оказываемых Сервисов,
          получения информационных и/или рекламных сообщений Общества или
          третьих лиц, предупреждения или пресечения незаконных и/или
          несанкционированных действий Пользователей или третьих лиц,
          обеспечения соблюдения требований действующего законодательства
          Российской Федерации, получения статистических и аналитических данных
          для улучшения функционирования Сайта и/или Сервисов, расширения
          спектра оказываемых Сервисов, получения информационных и/или рекламных
          сообщений Общества или третьих лиц, предупреждения или пресечения
          незаконных и/или несанкционированных действий Пользователей или
          третьих лиц, обеспечения соблюдения требований действующего
          законодательства Российской Федерации.
        </p>
        <p>
          5.10. Пользователь, направляя Заявку и/или совершая звонок в Общество
          также дает свое согласие на запись разговора с сотрудником Общества
          или оператором колл-центра, на направление ему по любым каналам связи,
          включая: SMS-оповещение, push-уведомление, отправку сообщений
          посредством WhatsАpp, звонком, голосовым сообщением, сообщением по
          электронной почте, информации, индивидуальных предложений и рекламных
          материалов о продуктах и услугах, оказываемых Обществом, связанных с
          Сайтом и предпринимательской деятельностью Общества, включая сообщения
          о действующих или разрабатываемых товарах и услугах Общества.
        </p>
        <p>
          5.11. Общество принимает все необходимые меры для защиты персональных
          данных Пользователя от неправомерного доступа, изменения, раскрытия
          или уничтожения. Общество вправе использовать предоставленную
          Пользователем информацию, в том числе персональные данные, в целях
          обеспечения соблюдения требований действующего законодательства
          Российской Федерации (в том числе в целях предупреждения и/или
          пресечения незаконных и/или противоправных действий пользователей).
          Раскрытие предоставленной Пользователем информации может быть
          произведено лишь в соответствии с действующим законодательством
          Российской Федерации по требованию суда, правоохранительных органов, а
          равно в иных предусмотренных законодательством Российской Федерации
          случаях. Согласие предоставляется с момента начала использования
          функционала Сайта, и действует в течение всего срока жизни физического
          лица. Согласие может быть отозвано Пользователем в любой момент путем
          передачи Обществу подписанного Пользователем письменного уведомления.
          Пользователь вправе отозвать свое согласие на обработку своих
          персональных данных, направив подписанное письменное уведомление об
          отзыве по адресу: 125375, г. Москва, вн.тер.г. муниципальный округ
          Пресненский, Большой Гнездниковский пер., д. 1, стр. 2, указав в
          уведомлении те персональные данные, согласие на обработку которых
          отзывается. В случае получения уведомления об отзыве согласия Общество
          прекращает обработку таких персональных данных и уничтожает
          персональные данные в разумные сроки.
        </p>
        <p>
          5.12. На Сайте применяется технология идентификации, основанная на
          использовании файлов cookies в соответствии с Политикой ООО «Карбон
          Копи» в отношении обработки персональных данных. Нахождение на Сайте
          более 10 секунд означает согласие Пользователя на использование
          Обществом файлов-cookie.
        </p>
        <p>
          5.13. При доступе Пользователя к Сайту на компьютер, используемый им
          для доступа, могут быть записаны файлы cookies, которые в дальнейшем
          будут использованы для автоматической идентификации Пользователя на
          Сайте, а также для сбора статистических данных.
        </p>
        <p>
          5.14. Если Пользователь полагает, что по тем или иным причинам
          использование технологии cookies для него неприемлемо, он вправе
          запретить сохранение файлов cookies на компьютерах, используемых
          Пользователем для доступа к Сайту, соответствующим образом настроив
          браузер.
        </p>
        <h3>6. Обязанности пользователя</h3>
        <p>6.1. При использовании Сайта Пользователю запрещается:</p>
        <p>
          6.1.1. Загружать, хранить, публиковать, распространять и предоставлять
          доступ или иным образом использовать любую информацию, которая:
          содержит угрозы, дискредитирует, оскорбляет, порочит честь и
          достоинство или деловую репутацию или нарушает неприкосновенность
          частной жизни других Пользователей или третьих лиц; нарушает права
          несовершеннолетних лиц; является вульгарной или непристойной, содержит
          порнографические изображения и тексты или сцены сексуального характера
          с участием несовершеннолетних; содержит сцены бесчеловечного обращения
          с животными; содержит описание средств и способов суицида, любое
          подстрекательство к его совершению; пропагандирует и/или способствует
          разжиганию расовой, религиозной, этнической ненависти или вражды,
          пропагандирует фашизм или идеологию расового превосходства; содержит
          экстремистские материалы; пропагандирует преступную деятельность или
          содержит советы, инструкции или руководства по совершению преступных
          действий; содержит информацию ограниченного доступа, включая, но не
          ограничиваясь, государственной и коммерческой тайной, информацией о
          частной жизни третьих лиц; содержит рекламу или описывает
          привлекательность употребления наркотических веществ, информацию о
          распространении наркотиков, рецепты их изготовления и советы по
          употреблению; носит мошеннический характер; а также нарушает иные
          права и интересы граждан и юридических лиц или требования
          законодательства Российской Федерации;
        </p>
        <p>
          6.1.2. Незаконно загружать, хранить, публиковать, распространять и
          предоставлять доступ или иным образом использовать интеллектуальную
          собственность Общества и третьих лиц.
        </p>
        <p>
          6.2. Пользователь обязуется действовать исключительно в соответствии с
          действующим законодательством и Соглашением, а также нести в
          соответствии с законодательством Российской Федерации полную
          ответственность за собственные действия и бездействие на Сайте и при
          использовании Сервисов.
        </p>
        <p>
          6.3. Доступные Пользователю Сервисы Сайта могут быть использованы
          исключительно в целях, для которых такие Сервисы предназначены.
          Пользователю запрещается использовать Сервисы, а также любую
          полученную на Сайте информацию не по назначению.
        </p>
        <p>
          6.4. Пользователь обязуется не использовать автоматические и иные
          программы для получения доступа на Сайт. Также не допускается
          использование, распространение, копирование и/или извлечение с Сайта
          ручным или автоматическим способом (с использованием программных
          средств) любых материалов или информации.
        </p>
        <p>
          6.5. Пользователь также обязуется: не предпринимать никаких действий,
          которые могут привести к непропорционально большой нагрузке на
          инфраструктуру Сайта; не копировать, не воспроизводить, не изменять,
          не распространять и не представлять общественности любую информацию,
          содержащуюся на Сайте (кроме Сведений, предоставленных самим
          Пользователем) без предварительного письменного разрешения Общества;
          не препятствовать работе Сайта, а также не препятствовать действию
          автоматических систем или процессов, с целью заблокировать или
          ограничить доступ на Сайт, не осуществлять действия, направленные на
          дестабилизацию функционирования Сайта, осуществлять попытки
          несанкционированного доступа к управлению Сайтом или его закрытым
          разделам.
        </p>
        <p>
          6.6. В целях пресечения или предотвращения нарушения Соглашения и/или
          причинения ущерба Обществу (например, DDoS-атаки или иные хакерские
          атаки, неавторизованное использование программных средств), Общество
          вправе ограничивать доступ Пользователей или третьих лиц к Сайте путем
          блокирования доступа к Сайту соответствующего ip-адреса или диапазона
          ip-адресов.
        </p>
        <p>
          6.7. Для целей доступа к отдельным Сервисам на Сайте Пользователем
          могут использоваться предоставляемые им уникальные идентификаторы
          (набор символов) Пользователя, направляемые на указанные Пользователем
          номера мобильного телефона посредством СМС- сообщений.
        </p>
        <h3>7. Гарантии и ответственность</h3>
        <p>
          7.1. Пользователь несет ответственность за действия, совершаемые на
          Сайте, в соответствии с действующим законодательством Российской
          Федерации, включая ответственность за содержание размещаемой им
          информации и нарушение прав третьих лиц.
        </p>
        <p>
          7.2. В рамках использования Сайта Пользователь обязуется предоставлять
          только достоверные сведения и несет ответственность за предоставленную
          им информацию. Пользователь обязуется своевременно актуализировать
          сведения посредством их редактирования на Сайте. Общество вправе
          запрашивать, а Пользователь обязан предоставлять по такому запросу
          документы и информацию, необходимые для определения Пользователя как
          стороны Соглашения и/или стороны, использующей соответствующий сервис,
          а также документы, подтверждающие достоверность представленных
          сведений и правомерность использования/предложения Товара.
        </p>
        <p>
          7.3. В процессе пользования Сервисами Сайта (в том числе при
          Регистрации, просмотре страниц Сайта и т.п.), Пользователь
          самостоятельно и добровольно принимает решение о предоставлении
          Обществу персональных и иных сведений о Пользователе (фамилия, имя,
          отчество Пользователя, адрес электронной почты, номер мобильного
          телефона, а также любая иная информация, предоставленная
          Пользователем, в том числе содержащаяся в сообщениях, направляемых
          через форму обратной связи в интерфейсе Сайта, информация о действиях
          Пользователя на Сайте и пр.).
        </p>
        <p>
          7.6. Сайт является инструментом, который предоставляет возможность
          приобретения товаров и услуг, реализация и/или приобретение которых
          разрешены действующим законодательством РФ.
        </p>
        <p>
          7.7. Общество не несет ответственности за любые причиненные
          Пользователям вред или убытки, которые возникли или могут возникнуть в
          силу неактуальности, неполноты и/или недостоверности информации,
          размещенной на Сайте.
        </p>
        <p>
          7.8. В случае отсутствия доступа к Сайту как полностью, так и частично
          по причине проведения профилактических работ или иных работ
          технического характера, обеспечивающих нормальное функционирование
          Сайта, Общество не несет ответственности перед Пользователями за
          отсутствие доступа к Сайту, включая невозможность использования Сайта
          и невозможность получения какой-либо информации, размещенной на Сайте.
        </p>
        <p>
          7.9. Сервисы Сайта предоставляются «как есть», это означает, что
          Общество не предоставляет каких-либо гарантий в отношении Сервисов и
          Сайта, в частности, не гарантирует, что Сервисы, их прямой или
          косвенный эффект и качество будут соответствовать требованиям и целям
          Пользователя, что Сервисы будут предоставляться непрерывно, надежно и
          без ошибок, что результаты, которые будут получены посредством
          использования Сервисов, будут точными, надежными и соответствовать
          ожиданиям Пользователя.
        </p>
        <p>
          7.10. Общество не несет ответственности за функционирование
          информационной системы, в которой содержится Сайт и доступ к которой
          обеспечивается посредством сети Интернет, включая систему электронного
          взаимодействия между Пользователями и Обществом, и не компенсирует
          Пользователям любые убытки, понесенные ими в связи с использованием
          указанной информационной системы.
        </p>
        <p>
          7.11. Общество не несет ответственности за обеспечение
          конфиденциальности и безопасность логинов и паролей Пользователя.
          Пользователь самостоятельно несет ответственность за безопасность
          (устойчивость к угадыванию) выбранных им логинов и паролей, а также
          самостоятельно обеспечивает их конфиденциальность. Пользователь
          самостоятельно несет ответственность за все действия (а также их
          последствия), осуществленные на Сайте с использованием своего логина и
          пароля. При этом все указанные действия считаются произведенными самим
          Пользователем и порождают у него соответствующие права и обязанности.
        </p>
        <p>
          7.12. Общество не несет ответственности за убытки, понесенные
          Пользователями в связи с риском изменений цен на товары и услуги,
          невозможности реализации товаров и услуг, а также за потерю информации
          Пользователем, за искажение информации или потерю сообщения,
          получаемого с использованием форм связи на Сайте.
        </p>
        <p>
          7.13. Ни при каких условиях Общество не несет ответственности перед
          Пользователем за убытки, включая любые прямые, косвенные, умышленные,
          случайные или последующие убытки любого характера проистекающие из
          данного Соглашения или из использования или невозможности
          использования Сайта и/или Сервисов (включая, но, не ограничиваясь,
          убытками, возникшими в результате потери деловой репутации,
          прекращения работы, технического сбоя, аварии или неисправности или
          любых коммерческих убытков, издержек или потерь, а равно упущенной
          выгоды или неосновательного обогащения) даже если Обществу было
          известно или должно было быть известно о возможности такого ущерба.
        </p>
        <p>
          7.14. При любых обстоятельствах ответственность Общества в
          соответствии со Статьей 15 Гражданского кодекса России ограничена 10
          000 (десятью тысячами) рублей и возлагается на Общество при наличии в
          ее действиях вины. При этом Общество не несет ответственности за
          убытки, причиненные вследствие непреодолимой силы, то есть
          чрезвычайных и непредотвратимых при данных условиях обстоятельств.
        </p>
        <p>
          7.15. Пользователь согласен, что Общество не несет ответственности за
          возможные убытки, причиненные Пользователю в связи с принятием мер
          пресечения или предотвращения нарушений на Сайте, связанных с
          ограничением/блокировкой доступа Пользователей к Сайту и/или Сервисам,
          а также ip-адресов согласно настоящему Соглашению.
        </p>
        <p>
          7.16 В случае нанесения ущерба третьим лицам, другим Пользователям или
          Сайту Пользователь обязуется возместить причиненный ущерб в полном
          объёме в соответствии с действующим законодательством Российской
          Федерации.
        </p>
        <p>
          7.17. Пользователь несет ответственность и все расходы (включая
          возмещение убытков, вреда, штрафов, судебных и иных расходов и
          издержек) в случае предъявления третьими лицами каких-либо претензий,
          включая, но не ограничиваясь претензиями, связанными с защитой
          интеллектуальных прав третьих лиц, и за какие-либо обязательства,
          возникшие у Сайта в связи с требованиями третьих лиц, связанные или
          возникшие вследствие нарушения Пользователем условий настоящего
          Соглашения. Пользователь обязуется принять все необходимые и возможные
          меры, направленные на исключение Общества из числа ответчиков.
        </p>
        <h3>8. Заключительные положения</h3>
        <p>
          8.1. Пользователь и Общество будут пытаться решить все возникшие между
          ними споры и разногласия путем переговоров. В случае невозможности
          разрешить споры и разногласия путем переговоров они подлежат
          рассмотрению в соответствующем суде в соответствии с территориальной
          подсудностью по месту нахождения Общества.
        </p>
        <p>
          8.2. При разрешении всех споров по настоящему Пользовательскому
          соглашению применяется действующее законодательство Российской
          Федерации.
        </p>
        <p>
          8.3. Ввиду безвозмездности использования Сервисов, предоставляемых
          Пользователю в рамках настоящего Соглашения, положения
          законодательства о защите прав потребителей не подлежат применению к
          отношениям между Пользователем и Обществом.
        </p>
        <p>
          8.4. Настоящее Пользовательское соглашение вступает в силу с момента
          начала пользования Пользователем Сайтом, независимо от факта
          Регистрации Пользователя на Сайте, и действует бессрочно.
        </p>
        <p>
          8.6. Общество оставляет за собой право по собственному усмотрению
          прекратить доступ Пользователя, нарушающего настоящее Соглашение, а
          также условия любого из Сервисов, иных правил, регламентирующих
          функционирование Сайта, к Сервисам как в целом, так и в части.
          Пользователь, доступ которого к Сервисам был прекращен, или Сведения
          которого перестали быть действительными, не имеет права создавать
          новую учетную запись на Сайте повторно (в том числе с использованием
          указанного Пользователем ранее на Сайте адреса электронной почты).
          Пользователь не имеет права использовать для доступа на Сайт Учетные
          данные другого Пользователя.
        </p>
        <p>
          8.7. Общество вправе, а Пользователь настоящим дает свое согласие на
          это, передать свои права и/или обязанности по настоящему
          Пользовательскому соглашению, как в целом, так и в части, третьей
          стороне.
        </p>
        <p>
          8.8. В случае передачи прав и/или обязанностей, как в целом, так и в
          части, по настоящему Соглашению третьей стороне, третья сторона имеет
          право предоставлять аналогичные или похожие услуги на другом сайте.
        </p>
        <p>
          8.9. Ничто в Соглашении не может пониматься как установление между
          Пользователем и Обществом агентских отношений, отношений товарищества,
          отношений по совместной деятельности, отношений личного найма, либо
          каких-то иных отношений, прямо не предусмотренных Соглашением.
        </p>
        <p>
          8.10. Признание отдельных частей настоящего Соглашения
          недействительными не отменяет действие других положений настоящего
          Соглашения.
        </p>
        <p>
          8.11. Во всем остальном, что не урегулировано Соглашением, Стороны
          руководствуются действующим законодательством Российской Федерации.
        </p>
        <p>8.12. Реквизиты Общества:</p>
        <p>ООО «Карбон Копи»</p>
        <p>
          Адрес местонахождения: 125375, г. Москва, вн.тер.г. муниципальный
          округ Пресненский, Большой Гнездниковский пер., д. 1, стр. 2
        </p>
        <p>ОГРН 1197746440847</p>
        <h3>Приложение № 1 к Пользовательскому соглашению</h3>
        <h3>Согласие на обработку персональных данных</h3>
        <p>
          Отправляя Заявку через форму обратной связи на сайте и/или совершая
          звонок на телефон Общества, указанный на сайте, и/или используя
          сервисы сайта я свободно, своей волей и в своем интересе выражаю ООО
          «Карбон Копи» (адрес местонахождения: 125375, г. Москва, вн.тер.г.
          муниципальный округ Пресненский, Большой Гнездниковский пер., д. 1,
          стр. 2, ОГРН 1197746440847) и(или) оператору, привлеченному по
          усмотрению или желанию ООО «Карбон Копи», согласие на обработку
          персональных данных, предполагающую сбор (непосредственно от
          заявителя, от третьих лиц, путем направления запросов в органы
          государственной власти, органы местного самоуправления, юридическим и
          физическим лицам, из иных общедоступных информационных ресурсов, из
          архивов), запись, систематизацию, накопление, хранение, уточнение
          (обновление, изменение), проверку, извлечение, использование, передачу
          (распространение, предоставление, доступ), полностью или частично
          третьим лицам, действующим на основании договоров, заключенных ими с
          Обществом, обезличивание, блокировку, удаление и уничтожение следующих
          моих персональных данных: фамилия, имя, отчество, дата и место
          рождения, пол, гражданство, серия и номер основного документа (включая
          его копию), удостоверяющего личность, сведений о дате выдачи
          указанного документам и выдавшем его органе, ИНН, страховой номер
          индивидуального лицевого счета, указанный в страховом свидетельстве
          обязательного пенсионного страхования, адрес регистрации и
          фактического проживания, контактная информация (домашний, рабочий,
          мобильный телефоны, электронные адреса), место работы и должность,
          сведения об имущественном положении, файлов-cookie для целей:
          предоставления доступа к Сайту/ к учетной записи на Сайте,
          предоставления консультационной поддержки в связи с использованием
          Сайта, направления расчетов и коммерческих предложений по реализации
          товаров и услуг Общества и/или партнеров Общества, получения
          статистических и аналитических данных для улучшения функционирования
          Сайта и/или Сервисов, расширения спектра оказываемых Сервисов,
          получения информационных и/или рекламных сообщений Общества или
          третьих лиц, предупреждения или пресечения незаконных и/или
          несанкционированных действий Пользователей или третьих лиц,
          обеспечения соблюдения требований действующего законодательства
          Российской Федерации.
        </p>
        <p>
          Обработка персональных данных осуществляется как с использованием
          средств автоматизации, в том числе в
          информационно-телекоммуникационных сетях, так и без использования
          таких средств.
        </p>
        <p>
          Настоящим Пользователь дает свое согласие на обозначенную обработку
          персональных данных Пользователей, а также на направление ему по любым
          каналам связи, включая: SMS-оповещение, push-уведомление, отправку
          сообщений посредством WhatsАpp, звонком, голосовым сообщением,
          сообщением по электронной почте, информации, индивидуальных
          предложений и рекламных материалов о продуктах и услугах, оказываемых
          Обществом, связанных с Сайтом и предпринимательской деятельностью
          Общества, включая сообщения о действующих или разрабатываемых товарах
          и услугах Общества.
        </p>
        <p>
          Согласие предоставляется с момента подписания мной настоящей Анкеты и
          на весь срок моей жизни.
        </p>
        <p>
          ООО «Карбон Копи» в соответствии с настоящим согласием и для
          достижения указанных выше целей вправе поручить обработку персональных
          данных третьим лицам, а также в случае привлечения третьих лиц к
          оказанию услуг и/или в случае передачи ООО «Карбон Копи» принадлежащих
          ему прав требования третьему лицу, вправе в необходимом объеме
          раскрывать/передавать информацию таким третьим лицам, их агентам и
          иным уполномоченным им лицам, а также предоставлять таким лицам
          соответствующие документы, содержащие такую информацию.
        </p>
        <p>
          Согласие может быть отозвано Пользователем в любой момент путем
          направления соответствующего подписанного письменного уведомления,
          направленного ООО «Карбон Копи» по адресу: 125375, г. Москва,
          вн.тер.г. муниципальный округ Пресненский, Большой Гнездниковский
          пер., д. 1, стр. 2 не менее чем за 1 (один) месяц.
        </p>
        <p>
          В случае отзыва настоящего согласия ООО «Карбон Копи» вправе
          продолжить обработку персональных данных в случаях и порядке,
          предусмотренных законодательством Российской Федерации.
        </p>
        <p>
          Настоящим я подтверждаю указанные при регистрации/направлении формы
          Заявки на Сайте/ сообщенные при звонке оператору персональные данные
          принадлежат лично мне; вся информация о моей личности, изложенная при
          регистрации на Сайте, является достоверной и полной; я внимательно и в
          полном объеме ознакомился с настоящим согласием, условия обработки
          моих персональных данных мне понятны и принимаются мною без каких-либо
          оговорок и ограничений.
        </p>
      </div>
    </div>
  );
}
