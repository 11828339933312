import * as React from "react";
import Box from "@mui/material/Box";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "axios";
import style from "./style.module.scss";
import ErrorIcon from "./../../assets/ErrorResponseIcon.svg";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import PositiveIcon from "./../../assets/PositiveResponseIcon.svg";
import Telegram from "../../assets/TelegramIcon.svg";

const textAnimation = {
  hidden: {
    y: 0,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
  }),
};
const useValidation = (value, validations) => {
  const [isEmpty, setEmpty] = useState(true);
  const [minLengthError, setMinLengthError] = useState(false);
  const [maxLengthError, setMaxLengthError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [innError, setInnError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [inputValid, setInputValid] = useState(false);

  useEffect(() => {
    for (const validation in validations) {
      switch (validation) {
        case "minLength":
          value.length < validations[validation]
            ? setMinLengthError(true)
            : setMinLengthError(false);
          break;
        case "isEmpty":
          value ? setEmpty(false) : setEmpty(true);
          break;
        case "maxLength":
          value.length > validations[validation]
            ? setMaxLengthError(true)
            : setMaxLengthError(false);
          break;
        case "isEmail":
          const re =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
          re.test(String(value).toLowerCase())
            ? setEmailError(false)
            : setEmailError(true);
          break;
        case "isInn":
          const reInn = /^(\d{10}|\d{12})$/;
          reInn.test(String(value).toLowerCase())
            ? setInnError(false)
            : setInnError(true);
          break;
        case "isName":
          const reName = /^[А-ЯЁа-яёA-Za-z]+$/;
          reName.test(String(value).toLowerCase())
            ? setNameError(false)
            : setNameError(true);
          break;
        case "isPhone":
          const rePhone = /^\+\d{1} \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
          rePhone.test(String(value).toLowerCase())
            ? setPhoneError(false)
            : setPhoneError(true);

          break;
      }
    }
  }, [value]);

  useEffect(() => {
    if (
      phoneError ||
      isEmpty ||
      maxLengthError ||
      minLengthError ||
      emailError ||
      innError ||
      nameError
    ) {
      setInputValid(false);
    } else {
      setInputValid(true);
    }
  }, [
    phoneError,
    isEmpty,
    maxLengthError,
    minLengthError,
    emailError,
    innError,
    nameError,
  ]);

  return {
    phoneError,
    nameError,
    innError,
    isEmpty,
    minLengthError,
    emailError,
    maxLengthError,
    inputValid,
  };
};

const useInput = (initialValue, validations) => {
  const [value, setValue] = useState(initialValue);
  const [isDirty, setDirty] = useState(false);
  const valid = useValidation(value, validations);

  const onChange = (e) => {
    setDirty(true);
    setValue(e.target.value);
  };

  return {
    value,
    setValue,
    onChange,
    isDirty,
    ...valid,
  };
};

export default function FeedbackWidget({ calculatorResult }) {
  const { t } = useTranslation();

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    showButton: true,
  });
  const { vertical, horizontal, open, showButton } = state;

  const handleClick = (newState) => () => {
    setState({ ...newState, open: true, showButton: false });
  };

  const handleClose = () => {
    setState({ ...state, open: false, showButton: true });
  };


  const buttons = showButton ? (
    <React.Fragment>
      <div style={{ position: "fixed", bottom: 20, right: 20, zIndex: 9999 }}>
        <IconButton
            onClick={handleClick({vertical: "bottom", horizontal: "right"})}
        >
          <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="65" height="65" rx="32.5" fill="#FD1A47"/>
            <path
                d="M41.705 39.245L42.29 43.985C42.44 45.23 41.105 46.1 40.04 45.455L33.755 41.72C33.065 41.72 32.39 41.675 31.73 41.585C32.84 40.28 33.5 38.63 33.5 36.845C33.5 32.585 29.81 29.135 25.25 29.135C23.51 29.135 21.905 29.63 20.57 30.5C20.525 30.125 20.51 29.75 20.51 29.36C20.51 22.535 26.435 17 33.755 17C41.075 17 47 22.535 47 29.36C47 33.41 44.915 36.995 41.705 39.245Z"
                stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M33.5 36.8449C33.5 38.6299 32.84 40.28 31.73 41.585C30.245 43.385 27.89 44.5399 25.25 44.5399L21.335 46.8649C20.675 47.2699 19.835 46.715 19.925 45.95L20.3 42.995C18.29 41.6 17 39.3649 17 36.8449C17 34.2049 18.41 31.88 20.57 30.5C21.905 29.63 23.51 29.135 25.25 29.135C29.81 29.135 33.5 32.5849 33.5 36.8449Z"
                stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </IconButton>
      </div>
    </React.Fragment>
  ) : (
      <React.Fragment>
        <div style={{ position: "fixed", bottom: 20, right: 20, zIndex: 9999 }}>
          <IconButton
              onClick={handleClick({vertical: "bottom", horizontal: "right"})}
          >
            <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="65" height="65" rx="32.5" fill="#FD1A47"/>
              <path d="M20 44L44 20" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M44 44L20 20" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </IconButton>
        </div>
      </React.Fragment>
  );

  return (
      <Box>
        {buttons}
        <Snackbar
            open={open}
            onClose={handleClose}
            key={vertical + horizontal}
        >
          <motion.div>
            <div style={{position: "fixed", bottom: 100, right: 20, zIndex: 9999}}>
              <a href="tel:+74994605810">
                <IconButton
                    onClick={handleClick({vertical: "bottom", horizontal: "right"})}
                >
                  <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="65" height="65" rx="32.5" fill="#282828"/>
                    <path
                        d="M46.955 41.495C46.955 42.035 46.835 42.59 46.58 43.13C46.325 43.67 45.995 44.18 45.56 44.66C44.825 45.47 44.015 46.055 43.1 46.43C42.2 46.805 41.225 47 40.175 47C38.645 47 37.01 46.64 35.285 45.905C33.56 45.17 31.835 44.18 30.125 42.935C28.4 41.675 26.765 40.28 25.205 38.735C23.66 37.175 22.265 35.54 21.02 33.83C19.79 32.12 18.8 30.41 18.08 28.715C17.36 27.005 17 25.37 17 23.81C17 22.79 17.18 21.815 17.54 20.915C17.9 20 18.47 19.16 19.265 18.41C20.225 17.465 21.275 17 22.385 17C22.805 17 23.225 17.09 23.6 17.27C23.99 17.45 24.335 17.72 24.605 18.11L28.085 23.015C28.355 23.39 28.55 23.735 28.685 24.065C28.82 24.38 28.895 24.695 28.895 24.98C28.895 25.34 28.79 25.7 28.58 26.045C28.385 26.39 28.1 26.75 27.74 27.11L26.6 28.295C26.435 28.46 26.36 28.655 26.36 28.895C26.36 29.015 26.375 29.12 26.405 29.24C26.45 29.36 26.495 29.45 26.525 29.54C26.795 30.035 27.26 30.68 27.92 31.46C28.595 32.24 29.315 33.035 30.095 33.83C30.905 34.625 31.685 35.36 32.48 36.035C33.26 36.695 33.905 37.145 34.415 37.415C34.49 37.445 34.58 37.49 34.685 37.535C34.805 37.58 34.925 37.595 35.06 37.595C35.315 37.595 35.51 37.505 35.675 37.34L36.815 36.215C37.19 35.84 37.55 35.555 37.895 35.375C38.24 35.165 38.585 35.06 38.96 35.06C39.245 35.06 39.545 35.12 39.875 35.255C40.205 35.39 40.55 35.585 40.925 35.84L45.89 39.365C46.28 39.635 46.55 39.95 46.715 40.325C46.865 40.7 46.955 41.075 46.955 41.495Z"
                        stroke="white" strokeWidth="1.5" strokeMiterlimit="10"/>
                  </svg>

                </IconButton>
              </a>
            </div>
            <div style={{position: "fixed", bottom: 180, right: 20, zIndex: 9999}}>
              <a href="https://t.me/carboncopy0?text=Здравствуйте! Меня интересует лизинг IT-оборудования">
                <IconButton
                    onClick={handleClick({vertical: "bottom", horizontal: "right"})}
                >
                  <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="65" height="65" rx="32.5" fill="#282828"/>
                    <g clipPath="url(#clip0_162_33)">
                      <path
                          d="M46.3734 18.6218C46.1201 18.3901 45.8098 18.2299 45.4742 18.1578C45.1385 18.0857 44.7898 18.1043 44.4637 18.2115L16.327 27.4866C15.5601 27.7393 15.0401 28.4238 15.0022 29.2303C14.9643 30.0368 15.4178 30.7671 16.1574 31.0908L23.1158 34.1353L25.2153 43.8725C25.3025 44.277 25.5078 44.6373 25.9427 44.7481C26.3826 44.8601 26.6935 44.6197 27.0217 44.374L32.1855 40.5074L38.2223 45.4473C38.5739 45.7352 39.0036 45.8853 39.4418 45.8853C39.6541 45.8851 39.865 45.8502 40.066 45.7818C40.7013 45.5663 41.1723 45.0478 41.3259 44.3947L46.9488 20.4883C47.0272 20.1542 47.0152 19.8051 46.9141 19.4771C46.8129 19.1491 46.6263 18.8539 46.3734 18.6218ZM27.4715 35.8798C27.4681 35.888 27.4647 35.8971 27.4615 35.9081L26.2975 39.9749L24.9985 33.9502L33.9308 28.9874L27.6858 35.5173C27.5878 35.6204 27.5146 35.7443 27.4715 35.8798ZM27.841 41.4126L28.3696 39.5655L28.8753 37.7986L30.6873 39.2814L27.841 41.4126ZM45.1193 20.058L39.4965 43.9643C39.4936 43.9762 39.4899 43.9926 39.4624 44.0019C39.4351 44.0111 39.4219 44.0006 39.4125 43.9928L32.8068 38.5873L32.8063 38.5869L29.7458 36.0824L39.5785 25.8013C39.7338 25.6388 39.8258 25.4259 39.8377 25.2014C39.8496 24.9769 39.7805 24.7556 39.6431 24.5776C39.5057 24.3996 39.3091 24.2768 39.0889 24.2314C38.8686 24.1861 38.6394 24.2212 38.4429 24.3304L23.8835 32.4197L16.9108 29.3689C16.8895 29.3596 16.8778 29.3545 16.8795 29.3185C16.8812 29.2827 16.8933 29.2786 16.9153 29.2714L45.0521 19.9964C45.0657 19.992 45.0811 19.9868 45.1037 20.0075C45.1263 20.0282 45.1226 20.044 45.1193 20.058Z"
                          fill="white"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_162_33">
                        <rect width="32" height="32" fill="white" transform="translate(15 16)"/>
                      </clipPath>
                    </defs>
                  </svg>
                </IconButton>
              </a>
            </div>
          </motion.div>
        </Snackbar>
      </Box>
  );
}
